import axios from 'axios';
import jwt_decode from 'jwt-decode';

// Api url preset
export const apiUrl = `https://language-tool.doorjames.dev/`;
/* export const apiUrl = `http://34.159.175.234:3002/`; */
/* export const apiUrl = `http://localhost:3002/`; */

// dev or prod to use console.log in error messages
const environment = 'prod';

// The codename of the base schema
export const baseLanguage = 'en';

// User roles in database
export const userRoles = ['Admin', 'Translator'];

// Translation status in database
export const status = ['Untranslated', 'Fuzzy', 'Translated'];

// Standard allowed branches
export const standartBranches = [
  'main',
  'master',
  'dev',
  'stage',
  'preview',
  'demo',
];

// Translate branch prefix
export const translateBranchPrefix = 'translate-';

// Items per page values
export const staticItemsPerPage = [25, 50, 75, 100];

//
// Show error message
//
export const showErrorMessage = async (error) => {
  try {
    if (environment === 'dev') {
      console.error(error);
    }

    if (error['message']) {
      alert(error['message']);
    } else {
      alert('Error processing the request');
    }
  } catch (error) {
    if (environment === 'dev') {
      console.error(error);
    }
    alert(error);
  }
};

//
// Register a user
//
export const register = async (newUser) => {
  try {
    const response = await axios.post(`${apiUrl}auth/register`, newUser);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
  }
};

//
// Login a user
//
export const login = async (user) => {
  try {
    const response = await axios.post(`${apiUrl}auth/login`, user);

    // If there is a response with data,
    // set the token in localStorage and return.
    if (response.data) {
      setOrRefreshToken(response.data);
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
  }
};

//
// Create a new repository
//
export const repositoryCreate = async (repositoryToCreate) => {
  try {
    const response = await axios.post(
      `${apiUrl}repository/create_repository`,
      repositoryToCreate,
      {
        headers: {
          'auth-token': localStorage.usertoken,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Update the repository
//
export const repositoryUpdate = async (id, repositoryToEdit) => {
  try {
    const response = await axios.put(
      `${apiUrl}repository/${id}`,
      repositoryToEdit,
      {
        headers: {
          'auth-token': localStorage.usertoken,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Delete the repository
//
export const repositoryDelete = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}repository/${id}`, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Create a new branch
//
export const branchCreate = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}branch/create_branch`, data, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Update the database
//
export const databaseUpdate = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}branch/update_db`, data, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Update the local repository and database
//
export const branchUpdate = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}branch/update_branch`, data, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Delete the branch
//
export const branchDelete = async (data) => {
  try {
    const response = await axios.delete(`${apiUrl}branch/delete_branch`, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
      data: {
        data: data,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Create a new language
//
export const languageCreate = async (data) => {
  try {
    const response = await axios.post(
      `${apiUrl}language/create_language`,
      data,
      {
        headers: {
          'auth-token': localStorage.usertoken,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return false;
  }
};

//
// Update a language
//
export const languageUpdate = async (data) => {
  try {
    const response = await axios.post(
      `${apiUrl}language/update_language`,
      data,
      {
        headers: {
          'auth-token': localStorage.usertoken,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return false;
  }
};

//
// Update the translation status in all language entries
//
export const translationStatusUpdate = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}language/update_status`, data, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return false;
  }
};

//
// Delete the language
//
export const languageDelete = async (data) => {
  try {
    const response = await axios.delete(`${apiUrl}language/delete_language`, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
      data: {
        data: data,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return false;
  }
};

//
// Update the base schema
//
export const updateSchema = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}language/update_schema`, data, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return false;
  }
};

//
// Download the correspond language json file
//
export const languageDownload = async (data) => {
  axios({
    url: `${apiUrl}language/download_language`, //your url
    method: 'POST',
    data: data,
    headers: {
      'auth-token': localStorage.usertoken,
    },
    responseType: 'blob', // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.codename}.json`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      showErrorMessage(error);
    });
};

//
// Download all language json files in zip
//
export const languageDownloadAll = async (data) => {
  axios({
    url: `${apiUrl}language/download_all`, //your url
    method: 'POST',
    data: data,
    headers: {
      'auth-token': localStorage.usertoken,
    },
    responseType: 'blob', // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'languages_json.zip'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      showErrorMessage(error);
    });
};

//
// Update the user profile
//
export const profileUpdate = async (id, profileToEdit) => {
  try {
    const response = await axios.put(
      `${apiUrl}auth/update/${id}`,
      profileToEdit,
      {
        headers: {
          'auth-token': localStorage.usertoken,
        },
      }
    );
    // If there is a response with data,
    // set the token in localStorage and return.
    if (response.data) {
      setOrRefreshToken(response.data);
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Update the user role from admin
//
export const userRoleUpdate = async (id, newUserRole) => {
  try {
    const response = await axios.put(
      `${apiUrl}auth/update_role/${id}`,
      newUserRole,
      {
        headers: {
          'auth-token': localStorage.usertoken,
        },
      }
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Delete the user profile
//
export const profileDelete = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}auth/delete_profile/${id}`, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Delete a user profile
//
export const userDelete = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}auth/delete_user/${id}`, {
      headers: {
        'auth-token': localStorage.usertoken,
      },
    });
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    showErrorMessage(error.response.data);
    return error.response.data;
  }
};

//
// Return the decoded token (if any)
//
export const getToken = () => {
  const token = localStorage.usertoken;
  const decoded = jwt_decode(token);
  return decoded;
};

//
// Save the user role to localStorage
//
export const setUserRole = () => {
  const userRole = getToken();
  localStorage.setItem('userrole', userRole.user.role);
};

//
// Set or Refresh the token and user role into localStorage
//
export const setOrRefreshToken = (token) => {
  // Remove token and user role if any
  if (localStorage.usertoken) {
    localStorage.removeItem('usertoken');
    localStorage.removeItem('userrole');
  }

  // Save the token to localStorage
  localStorage.setItem('usertoken', token);
  // Save the user role to localStorage
  setUserRole();
};

import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  apiUrl,
  userRoles,
  showErrorMessage,
} from '../../components/HelperFunctions';
import { RepositoryContext } from '../../context/RepositoryContext';
import { BranchContext } from '../../context/BranchContext';
import axios from 'axios';
import './index.css';

const Branches = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [repositoryData] = useContext(RepositoryContext);
  const [, setBranch] = useContext(BranchContext);
  const [isLoading, setIsLoading] = useState(true);

  // States for pagination
  const [pages, setPages] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(true);
  const [pageData, setPageData] = useState([]);
  const itemsPerPage = 10;

  //
  // Fetch the branch data from the repository
  //
  useEffect(() => {
    // Get all branches for the given repository
    const fetchAllData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${apiUrl}branch/${repositoryData.repositoryFolder}`,
          {
            headers: {
              'auth-token': localStorage.usertoken,
            },
          }
        );
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(error.response.data);
      }
    };

    fetchAllData();
  }, [repositoryData]);

  //
  // Setup pagination everytime the data changes
  //
  useEffect(() => {
    setIsLoading(true);
    // Reset the nedded states
    let rows = [];
    setActualPage(0);
    setPrevDisable(true);
    setNextDisable(true);

    // Calculate how many pages we have correspond the data
    let pages = Math.round(data.length / itemsPerPage + 0.4 - 1);
    setPages(pages);

    // If we have more than one page, enable the next button
    if (pages > 0) {
      setNextDisable(false);
    }

    // Check if the data lenght is less than items per page value.
    // If yes, we set the data lenght to the for loop to avoid
    // adding undefined values on the array.
    let maxItems = itemsPerPage;
    if (data.length < itemsPerPage) {
      maxItems = data.length;
    }

    // Set the page data
    for (let i = 0; i < maxItems; i++) {
      rows.push(data[i]);
    }

    setPageData([...rows]);
    setIsLoading(false);
  }, [data]);

  //
  // Route to branchEdit with the correspond id
  //
  const handleEditClick = (e) => {
    const branchToEdit = pageData[e.target.id];
    history.push('/branch_edit', { params: branchToEdit });
  };

  //
  // Update the local repository / database
  //
  const handleUpdateClick = (e) => {
    const branchToUpdate = pageData[e.target.id];
    history.push('/branch_update', { params: branchToUpdate });
  };

  //
  // Route to laguages with the correspond branch id
  //
  const handleSelectClick = (e) => {
    const branchSelected = pageData[e.target.id];

    // Set the selected branch data to branch context
    setBranch(branchSelected);

    // Switch to laguages page only if the local branch and the database
    // are updated on an active branch. If the branch is not active
    // (deleted remotely), allow the user to the languages for maybe
    // download the json files and restore the remote branch.
    if (
      (branchSelected['updated'] && branchSelected['updatedDb']) ||
      !branchSelected['active']
    ) {
      history.push('/languages', { params: branchSelected });
    } else {
      alert('The selected branch needs update. Please click on update icon.');
    }
  };

  //
  // Set the updated icon depending the booleans
  //
  const setUpdatedIcon = (active, updated, updatedDb, index) => {
    // If the active is false return the x icon
    if (!active) {
      return <i className="bi bi-x-lg"></i>;
    }

    // Return check icon if the updated and updatedDb are true
    if (updated && updatedDb) {
      return <i className="bi bi-check-lg"></i>;
    } else {
      // Else return the update button
      return (
        <button className="btn" type="button">
          <i
            className="bi bi-arrow-clockwise"
            onClick={handleUpdateClick}
            id={index}
          ></i>
        </button>
      );
    }
  };

  //
  // Handle when previous button is clicked
  //
  const handlePrevClick = (e) => {
    // Get and update the new page state
    const prevPage = actualPage - 1;
    setActualPage(prevPage);

    // Define the new indexes to get the page data
    let startIndex = prevPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // Disable the prev button if we are
    // on the first page
    if (prevPage === 0) {
      setPrevDisable(true);
    }
    // Also enable the next button if not already
    if (nextDisable) {
      setNextDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  //
  // Handle when next button is clicked
  //
  const handleNextClick = (e) => {
    // Get and update the new page state
    const nextPage = actualPage + 1;
    setActualPage(nextPage);

    // Define the new indexes to get the page data
    let startIndex = nextPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // If we are on the last page, check if the
    // data are less than the end index.
    if (endIndex > data.length) {
      endIndex = data.length;
    }

    // Disable the next button if we are
    // on the last page
    if (nextPage === pages) {
      setNextDisable(true);
    }
    // Also enable the previous button if not already
    if (prevDisable) {
      setPrevDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  return (
    <div className="container fadeIn">
      <br />
      <h2 className="text-center">Branches</h2>
      <br />
      <div className="row justify-content-end">
        <button
          className="btn btn-danger button-right"
          type="button"
          onClick={() => {
            history.push('/translations');
          }}
        >
          <i className="bi bi-arrow-left"></i> Back
        </button>
        {localStorage.userrole === userRoles[0] && (
          <button
            className="btn btn-success button-right"
            type="button"
            onClick={() => {
              history.push('/branch_new');
            }}
          >
            <i className="bi bi-plus-lg"></i> New
          </button>
        )}
      </div>
      <br />
      {isLoading ? (
        <>
          <h5 className="text-center">Loading...</h5>
        </>
      ) : (
        <>
          <h6>Repository: {repositoryData.repositoryFolder}</h6>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Branch</th>
                <th scope="col">Active</th>
                <th scope="col">Updated</th>
                <th scope="col">Edit</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody>
              {pageData.map((item, index) => (
                <tr key={index} className="fadeIn">
                  <th scope="col">{actualPage * itemsPerPage + (index + 1)}</th>
                  <td>{item['branch']}</td>
                  <td>
                    {item['active'] ? (
                      <i className="bi bi-check-lg"></i>
                    ) : (
                      <i className="bi bi-x-lg"></i>
                    )}
                  </td>
                  <td>
                    {setUpdatedIcon(
                      item['active'],
                      item['updated'],
                      item['updatedDb'],
                      index
                    )}
                  </td>
                  <td>
                    {localStorage.userrole === userRoles[0] && (
                      <button className="btn" type="button">
                        <i
                          className="bi bi-pencil"
                          onClick={handleEditClick}
                          id={index}
                        ></i>
                      </button>
                    )}
                  </td>
                  <td>
                    <button className="btn" type="button">
                      <i
                        className="bi bi-arrow-right-circle"
                        onClick={handleSelectClick}
                        id={index}
                      ></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handlePrevClick}
            disabled={prevDisable}
          >
            <i className="bi bi-arrow-left-square-fill"></i> Prev
          </button>
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handleNextClick}
            disabled={nextDisable}
          >
            Next <i className="bi bi-arrow-right-square-fill"></i>
          </button>
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default Branches;

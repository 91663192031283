import { useState, createContext } from 'react';

export const BranchContext = createContext();

export const BranchController = (props) => {
  const [branch, setBranch] = useState([]);

  return (
    <BranchContext.Provider value={[branch, setBranch]}>
      {props.children}
    </BranchContext.Provider>
  );
};

import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { RepositoryController } from './context/RepositoryContext';
import { BranchController } from './context/BranchContext';

// Navigation bar
import Navbar from './components/Navbar';

// Pages
import Landing from './Pages/Landing';
import Login from './Pages/Login';
import Repositories from './Pages/Repositories';
import Branches from './Pages/Branches';
import Languages from './Pages/Languages';
/* import Register from './Pages/Register'; */
import Users from './Pages/Users';
import Profile from './Pages/Profile';

// Components
import UserEdit from './components/UserEdit';
import UserNew from './components/UserNew';
import ProfileEdit from './components/ProfileEdit';
import RepositoryNew from './components/RepositoryNew';
import RepositoryEdit from './components/RepositoryEdit';
import RepositoryReport from './components/RepositoryReport';
import BranchNew from './components/BranchNew';
import BranchEdit from './components/BranchEdit';
import BranchUpdate from './components/BranchUpdate';
import LanguageNew from './components/LanguageNew';
import LanguageEdit from './components/LanguageEdit';
import LanguageReport from './components/LanguageReport';
import LanguageTranslate from './components/LanguageTranslate';
import SchemaEdit from './components/SchemaEdit';

const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="App-header">
          <Navbar />
          <Route exact path="/" component={Landing} />
          <div className="container">
            <RepositoryController>
              <BranchController>
                <Route exact path="/login" component={Login} />
                {/* <Route exact path="/register" component={Register} /> */}
                <Route exact path="/translations" component={Repositories} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/user_edit" component={UserEdit} />
                <Route exact path="/user_new" component={UserNew} />
                <Route exact path="/profile_edit" component={ProfileEdit} />
                <Route exact path="/repository_new" component={RepositoryNew} />
                <Route
                  exact
                  path="/repository_edit"
                  component={RepositoryEdit}
                />
                <Route
                  exact
                  path="/repository_report"
                  component={RepositoryReport}
                />
                <Route exact path="/branches" component={Branches} />
                <Route exact path="/branch_new" component={BranchNew} />
                <Route exact path="/branch_edit" component={BranchEdit} />
                <Route exact path="/branch_update" component={BranchUpdate} />
                <Route exact path="/languages" component={Languages} />
                <Route exact path="/language_new" component={LanguageNew} />
                <Route exact path="/language_edit" component={LanguageEdit} />
                <Route
                  exact
                  path="/language_report"
                  component={LanguageReport}
                />
                <Route
                  exact
                  path="/language_translate"
                  component={LanguageTranslate}
                />
                <Route exact path="/schema_edit" component={SchemaEdit} />
              </BranchController>
            </RepositoryController>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;

import { useState } from 'react';
import { repositoryUpdate, repositoryDelete } from '../HelperFunctions';
import { useHistory, useLocation } from 'react-router-dom';
import './index.css';

const RepositoryEdit = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const repositoryToEdit = location.state.params;
  const [deleteRepository, setDeleteRepository] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [data, setData] = useState({
    name: repositoryToEdit.name,
    description: repositoryToEdit.description,
    repositoryUrl: repositoryToEdit.repositoryUrl,
  });

  //
  // Submit the changes
  //
  const onSubmit = (e) => {
    e.preventDefault();

    // Set is loading to true to add
    // the loading indicator to submit button
    setIsLoading(true);

    // Check if user choose to delete
    if (deleteRepository === 'DELETE') {
      repositoryDelete(repositoryToEdit._id).then((res) => {
        if (res) {
          history.push('/translations');
        }
      });
    } else {
      // If not, update the repository data
      repositoryUpdate(repositoryToEdit._id, data).then((res) => {
        if (res) {
          history.push('/translations');
        }
      });
    }
  };

  //
  // Change the correspond object field
  //
  const onChange = (e) => {
    let keyName = e.target.name;
    let value = e.target.value;
    setData((previous) => {
      return {
        ...previous,
        [keyName]: value,
      };
    });
    setSubmitDisable(false);
  };

  //
  // Change the delete field
  //
  const onChangeDelete = (e) => {
    let value = e.target.value;
    setDeleteRepository(value);
    setSubmitDisable(false);
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <form onSubmit={onSubmit}>
            <h2 className="text-center">Edit or delete the Repository</h2>
            <br />
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={data.name}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className="form-control"
                name="description"
                placeholder="Enter description"
                value={data.description}
                required
                onChange={onChange}
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <label htmlFor="repositoryUrl">Repository URL</label>
              <input
                type="text"
                className="form-control"
                name="repositoryUrl"
                value={data.repositoryUrl}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="delete" style={{ color: 'red' }}>
                Delete
              </label>
              <input
                type="text"
                className="form-control"
                name="delete"
                placeholder="Enter DELETE to delete the repository"
                value={deleteRepository}
                onChange={onChangeDelete}
              />
            </div>
            <div className="row justify-content-end">
              {isLoading ? (
                <>
                  <button
                    className="btn btn-success button-right"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    This may take a few minutes. Please wait...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-danger button-right"
                    type="button"
                    onClick={() => {
                      history.push('/translations');
                    }}
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success button-right"
                    disabled={submitDisable}
                  >
                    <i className="bi bi-check-lg"></i> Submit
                  </button>
                </>
              )}
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default RepositoryEdit;

import { Link, withRouter, useHistory } from 'react-router-dom';

const Navbar = () => {
  let history = useHistory();

  const logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('usertoken');
    localStorage.removeItem('userrole');
    history.push(`/`);
  };

  const loginLink = (
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/login" className="nav-link">
          Login
        </Link>
      </li>
      {/* <li className="nav-item">
        <Link to="/register" className="nav-link">
          Register
        </Link>
      </li> */}
    </ul>
  );

  const userAdminLink = (
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/translations" className="nav-link">
          Translations
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/users" className="nav-link">
          Users
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/profile" className="nav-link">
          Profile
        </Link>
      </li>
      <li className="nav-item">
        <a href="#endregion" onClick={logOut} className="nav-link">
          Logout
        </a>
      </li>
    </ul>
  );

  const userTranslatorLink = (
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/translations" className="nav-link">
          Translations
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/profile" className="nav-link">
          Profile
        </Link>
      </li>
      <li className="nav-item">
        <a href="#endregion" onClick={logOut} className="nav-link">
          Logout
        </a>
      </li>
    </ul>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark rounded">
      <div className="navbar-collapse justify-content-md-center">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
        </ul>
        {localStorage.usertoken
          ? localStorage.userrole === 'Admin'
            ? userAdminLink
            : userTranslatorLink
          : loginLink}
      </div>
    </nav>
  );
};

export default withRouter(Navbar);

import { useState, useContext } from 'react';
import { databaseUpdate, branchUpdate } from '../HelperFunctions';
import { useHistory, useLocation } from 'react-router-dom';
import { RepositoryContext } from '../../context/RepositoryContext';
import './index.css';

const BranchUpdate = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const branchToUpdate = location.state.params;
  const [repositoryData] = useContext(RepositoryContext);
  const [isLoading, setIsLoading] = useState(false);

  // Submit the changes
  const onSubmit = (e) => {
    e.preventDefault();

    // Set is loading to true to add
    // the loading indicator to submit button
    setIsLoading(true);

    // Update local repository and database
    // if both update and updateDb are false.
    if (!branchToUpdate['updated'] && !branchToUpdate['updatedDb']) {
      const data = {
        repositoryName: repositoryData.repositoryFolder,
        branchToUpdate: branchToUpdate['branch'],
      };
      branchUpdate(data).then((res) => {
        if (res) {
          history.push('/branches');
        }
      });
    }

    // Update only the database
    // if update is true and updateDb is false.
    if (branchToUpdate['updated'] && !branchToUpdate['updatedDb']) {
      const data = {
        repositoryName: repositoryData.repositoryFolder,
        newBranch: branchToUpdate['branch'],
      };
      databaseUpdate(data).then((res) => {
        if (res) {
          history.push('/branches');
        }
      });
    }
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <form onSubmit={onSubmit}>
            <h2 className="text-center">Update Branch/Database</h2>
            <br />
            <div className="form-group">
              <label htmlFor="branch">Branch</label>
              <input
                type="text"
                className="form-control"
                name="branch"
                value={branchToUpdate['branch']}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="branch">Local branch is updated</label>
              <input
                type="text"
                className="form-control"
                name="branch"
                value={branchToUpdate['updated'] ? 'Yes' : 'No'}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="branch">Database is updated</label>
              <input
                type="text"
                className="form-control"
                name="branch"
                value={branchToUpdate['updatedDb'] ? 'Yes' : 'No'}
                readOnly
              />
            </div>
            <div className="row justify-content-end">
              {isLoading ? (
                <>
                  <button
                    className="btn btn-success button-right"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    This may take a few minutes. Please wait...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-danger button-right"
                    type="button"
                    onClick={() => {
                      history.push('/branches');
                    }}
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success button-right"
                  >
                    <i className="bi bi-check-lg"></i> Submit
                  </button>
                </>
              )}
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default BranchUpdate;

import { useState, useContext } from 'react';
import { branchDelete } from '../HelperFunctions';
import { useHistory, useLocation } from 'react-router-dom';
import { RepositoryContext } from '../../context/RepositoryContext';
import './index.css';

const BranchEdit = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const branchToDelete = location.state.params;
  const [repositoryData] = useContext(RepositoryContext);
  const [deleteBranch, setDeleteBranch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Submit the changes
  const onSubmit = (e) => {
    e.preventDefault();

    // Set is loading to true to add
    // the loading indicator to submit button
    setIsLoading(true);

    // Check if user choose to delete
    if (deleteBranch === 'DELETE') {
      const data = {
        repositoryName: repositoryData.repositoryFolder,
        branchToDelete: branchToDelete['branch'],
      };
      branchDelete(data).then((res) => {
        if (res) {
          history.push('/branches');
        }
      });
    }
  };

  // Change the delete field
  const onChangeDelete = (e) => {
    let value = e.target.value;
    setDeleteBranch(value);
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <form onSubmit={onSubmit}>
            <h2 className="text-center">Delete the Branch</h2>
            <br />
            <div className="form-group">
              <label htmlFor="branch">Branch</label>
              <input
                type="text"
                className="form-control"
                name="branch"
                value={branchToDelete['branch']}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="delete" style={{ color: 'red' }}>
                Delete
              </label>
              <input
                type="text"
                className="form-control"
                name="delete"
                placeholder="Enter DELETE to delete the repository"
                value={deleteBranch}
                onChange={onChangeDelete}
              />
            </div>
            <div className="row justify-content-end">
              {isLoading ? (
                <>
                  <button
                    className="btn btn-success button-right"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    This may take a few minutes. Please wait...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-danger button-right"
                    type="button"
                    onClick={() => {
                      history.push('/branches');
                    }}
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success button-right"
                    disabled={!deleteBranch}
                  >
                    <i className="bi bi-check-lg"></i> Submit
                  </button>
                </>
              )}
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default BranchEdit;

import { useState, createContext } from 'react';

export const RepositoryContext = createContext();

export const RepositoryController = (props) => {
  const [repository, setRepository] = useState([]);

  return (
    <RepositoryContext.Provider value={[repository, setRepository]}>
      {props.children}
    </RepositoryContext.Provider>
  );
};

import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { RepositoryContext } from '../../context/RepositoryContext';
import { BranchContext } from '../../context/BranchContext';
import { languageCreate } from '../../components/HelperFunctions';
import { isoLangs } from '../../assets/isoLangs';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './index.css';

const LanguageNew = () => {
  let history = useHistory();
  const location = useLocation();
  const branchLanguages = location.state.params;
  const [existingLanguages, setExistingLanguages] = useState([]);
  const [isoLanguages, setIsoLanguages] = useState([]);
  const [filteredIsoLanguages, setFilteredIsoLanguages] = useState([]);
  const [newLanguageString, setNewLanguageString] = useState('');
  const [searchData, setSearchData] = useState('');
  const [cloneFromLanguageString, setCloneFromLanguageString] = useState(
    'English | en | English'
  );
  const [repositoryData] = useContext(RepositoryContext);
  const [branchData] = useContext(BranchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const data = {
    repositoryName: repositoryData.repositoryFolder,
    branch: branchData['branch'],
    newLanguage: '',
    cloneFromLanguage: '',
  };

  useEffect(() => {
    // Get and set the branch languages and the iso languages
    const getSetLanguages = async () => {
      try {
        // Array to keep the codenames of the existing languages
        const existingLanguages = [];

        // Set the existing branch languages and format them
        // for the dropdown list.
        branchLanguages.forEach((e) => {
          const languageCodename = e.codename;
          const language = e.language;
          const nativeName = e.nativeName;
          const finalLanguageString = `${language} | ${languageCodename} | ${nativeName}`;
          setExistingLanguages((previous) => {
            return [...previous, finalLanguageString];
          });

          // Add the codename to the existing languages array
          existingLanguages.push(languageCodename);
        });

        // Set the iso languages and format them
        // for the dropdown list.
        Object.entries(isoLangs).forEach((entry) => {
          const [key, value] = entry;

          // Set the language string only if the language
          // does not exist already in the branch languages
          if (!existingLanguages.includes(key)) {
            const finalLanguageString = `${value['name']} | ${key} | ${value['nativeName']}`;
            setIsoLanguages((previous) => {
              return [...previous, finalLanguageString];
            });
          }
        });
      } catch (error) {
        alert(error);
      }
    };

    // Set the languages if not already
    if (existingLanguages.length === 0) {
      getSetLanguages();
    }
  }, [existingLanguages, branchLanguages]);

  //
  // Submit the changes
  //
  const onSubmit = (e) => {
    e.preventDefault();

    // Set is loading to true to add
    // the loading indicator to submit button
    setIsLoading(true);

    // Check if both dropdown values are set
    if (!newLanguageString || !cloneFromLanguageString) {
      alert('Please choose both values');
      setIsLoading(false);
    } else {
      // Set the data and create the new language.
      // Split the values from dropdowns and set only the codenames.
      const newLanguageStringSplit = newLanguageString.split(' | ');
      const cloneFromLanguageStringSplit = cloneFromLanguageString.split(' | ');
      data.newLanguage = newLanguageStringSplit[1];
      data.cloneFromLanguage = cloneFromLanguageStringSplit[1];
      languageCreate(data).then((res) => {
        if (res) {
          setIsLoading(false);
          history.push('/languages');
        } else {
          // There is an error in the response (merge conflict).
          // Redirect to the branches page to update the branch.
          history.push('/branches');
        }
      });
    }
  };

  //
  // Change the search field and use the value to filter out
  // the iso languages array and set the new language variable
  // if there is only one entry.
  //
  const onChange = (e) => {
    let value = e.target.value;

    // If there is a value proceed with filtering
    if (value) {
      setSearchData(value);

      // Filter the iso languages array and set it
      const filteredArray = isoLanguages.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredIsoLanguages(filteredArray);

      // If the filtered array contains only one entry,
      // set the value to new language string.
      if (filteredArray.length === 1) {
        const entry = filteredArray[0];
        setNewLanguageString(entry);
        setSubmitDisable(false);
      }
    } else {
      // Else, there is no value, reset the data
      // and disable the submit button.
      setSearchData('');
      setFilteredIsoLanguages([]);
      setNewLanguageString('');
      setSubmitDisable(true);
    }
  };

  //
  // Change the new language from dropdown
  //
  const onDropdownChange = (e) => {
    const value = e.value;
    setNewLanguageString(value);
    setSearchData(value);
    setSubmitDisable(false);

    // Empty the filtered array
    setFilteredIsoLanguages([]);
  };

  //
  // Change the clone from language from dropdown
  //
  const onDropdownCloneChange = (e) => {
    const value = e.value;
    setCloneFromLanguageString(value);
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <form onSubmit={onSubmit}>
            <h2 className="text-center">Create a new language</h2>
            <br />
            <div className="form-group">
              <label htmlFor="search">Search</label>
              <input
                type="text"
                className="form-control"
                name="search"
                placeholder="Type search term"
                value={searchData}
                onChange={onChange}
                maxLength="30"
              />
            </div>
            <div className="form-group">
              <label htmlFor="newLanguage">New Language</label>{' '}
              <Dropdown
                options={searchData ? filteredIsoLanguages : isoLanguages}
                onChange={onDropdownChange}
                value={newLanguageString}
                placeholder="Select the new language"
              />
            </div>
            <div className="form-group">
              <label htmlFor="cloneFromLanguage">Language to clone from</label>{' '}
              <Dropdown
                options={existingLanguages}
                onChange={onDropdownCloneChange}
                value={cloneFromLanguageString}
                placeholder="Select a language to clone from"
              />
            </div>
            <div className="row justify-content-end">
              {isLoading ? (
                <>
                  <button
                    className="btn btn-success button-right"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    This may take a few minutes. Please wait...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-danger button-right"
                    type="button"
                    onClick={() => {
                      history.push('/languages');
                    }}
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success button-right"
                    disabled={submitDisable}
                  >
                    <i className="bi bi-check-lg"></i> Submit
                  </button>
                </>
              )}
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default LanguageNew;

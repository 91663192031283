import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReportList } from '../ReportList';
import './index.css';

const RepositoryReport = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const repositoryData = location.state.params;
  const [branches, setBranches] = useState([]);

  //
  // Get all branches from the repository
  // and set them to branches data.
  //
  useEffect(() => {
    // Set all branches from selected repository data
    const getBranches = async () => {
      try {
        Object.entries(repositoryData.branches).forEach((entry) => {
          const [key, value] = entry;
          if (value) {
            setBranches((previous) => {
              return [...previous, key];
            });
          }
        });
      } catch (error) {
        alert(error);
      }
    };

    // Set the branches if not already
    if (branches.length === 0) {
      getBranches();
    }
  }, [branches, repositoryData]);

  //
  // Print the report
  //
  const handlePrintClick = () => {
    window.print();
  };

  return (
    <div className="container fadeIn">
      <div className="col-md-11 mt-5 mx-auto">
        <h2 className="text-center">Repository Report</h2>
        <div className="row justify-content-end">
          <button
            className="btn btn-danger button-right printPageButton"
            type="button"
            onClick={() => {
              history.push('/translations');
            }}
          >
            <i className="bi bi-arrow-left"></i> Back
          </button>
          <button
            className="btn btn-primary button-right printPageButton"
            type="button"
            onClick={handlePrintClick}
          >
            <i className="bi bi-printer"></i> Print
          </button>
        </div>
      </div>
      <div className="col-md-12 mx-auto">
        {branches.map((item, index) => (
          <ReportList
            repository={repositoryData.repositoryFolder}
            branch={item}
            key={index}
          />
        ))}
      </div>
      <br />
      <br />
    </div>
  );
};

export default RepositoryReport;

import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BranchContext } from '../../context/BranchContext';
import ProgressBar from '@ramonak/react-progress-bar';
import './index.css';

const LanguageReport = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const languageData = location.state.params;
  const [branchData] = useContext(BranchContext);

  //
  // Get and return the date to locale string
  //
  const dateToLocalstring = (dateToChange) => {
    const getDate = new Date(dateToChange);
    const finalDate = getDate.toLocaleString();
    return finalDate;
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <h2 className="text-center">Language Report</h2>
          <br />
          <div className="form-group">
            <label htmlFor="branch">Branch</label>
            <input
              type="text"
              className="form-control"
              name="branch"
              value={branchData['branch']}
              readOnly
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="language">Language</label>
              <input
                type="text"
                className="form-control"
                name="language"
                value={languageData.language}
                readOnly
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="codename">Codename</label>
              <input
                type="text"
                className="form-control"
                name="codename"
                value={languageData.codename}
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-10">
              <label htmlFor="nativeName">Native Name</label>
              <input
                type="text"
                className="form-control"
                name="nativeName"
                value={languageData.nativeName}
                readOnly
              />
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="strings">Strings</label>
              <input
                type="text"
                className="form-control"
                name="strings"
                value={languageData.strings}
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="lastEdited">Last Edited</label>
              <input
                type="text"
                className="form-control"
                name="lastEdited"
                value={dateToLocalstring(languageData.lastEdited)}
                readOnly
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="editedFrom">Edited From</label>
              <input
                type="text"
                className="form-control"
                name="editedFrom"
                value={languageData.editedFrom}
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="translated" style={{ color: 'green' }}>
              Translated
            </label>
            <ProgressBar
              animateOnRender={true}
              bgColor="green"
              labelColor="green"
              labelAlignment="outside"
              completed={languageData.translated}
            />
          </div>
          <div className="form-group">
            <label htmlFor="fuzzy" style={{ color: 'orange' }}>
              Fuzzy
            </label>
            <ProgressBar
              animateOnRender={true}
              bgColor="orange"
              labelColor="orange"
              labelAlignment="outside"
              completed={languageData.fuzzy}
            />
          </div>
          <div className="form-group">
            <label htmlFor="untranslated" style={{ color: 'red' }}>
              Untranslated
            </label>
            <ProgressBar
              animateOnRender={true}
              bgColor="red"
              labelColor="red"
              labelAlignment="outside"
              completed={languageData.untranslated}
            />
          </div>
          <div className="row justify-content-end">
            <button
              className="btn btn-danger button-right"
              type="button"
              onClick={() => {
                history.push('/languages');
              }}
            >
              <i className="bi bi-arrow-left"></i> Back
            </button>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default LanguageReport;

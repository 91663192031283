import React from 'react';
import './index.css';

const Landing = () => {
  return (
    <div className="container">
      <div className="col-md-6 mt-5 mx-auto">
        <br />
        <br />
        <h1 className="text-center slideInLeft">Welcome to</h1>
        <br />
        <h1 className="text-center slideInRight">Awesome Translator</h1>
      </div>
    </div>
  );
};

export default Landing;

import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  apiUrl,
  status,
  staticItemsPerPage,
  showErrorMessage,
  languageUpdate,
} from '../../components/HelperFunctions';
import { RepositoryContext } from '../../context/RepositoryContext';
import { BranchContext } from '../../context/BranchContext';
import axios from 'axios';
import './index.css';

const LanguageTranslate = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const languageData = location.state.params;
  const [baseSchemaData, setBaseSchemaData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [dataToSave, setDataToSave] = useState([]);
  const [search, setSearch] = useState('');
  const [dropdownStatus, setDropdownStatus] = useState('');
  const [repositoryData] = useContext(RepositoryContext);
  const [branchData] = useContext(BranchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [saveDisable, setSaveDisable] = useState(true);

  // States for pagination
  const [pages, setPages] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(staticItemsPerPage[0]);

  //
  // Fetch the language data on the beginning
  //
  useEffect(() => {
    // Get the language strings
    const fetchData = async () => {
      try {
        const languageStringsToFetch = {
          repositoryName: repositoryData.repositoryFolder,
          branch: branchData['branch'],
          codename: languageData.codename,
        };

        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}language/fetch_language`,
          languageStringsToFetch,
          {
            headers: {
              'auth-token': localStorage.usertoken,
            },
          }
        );
        setData(response.data.languageStrings);
        setAllData(response.data.languageStrings);
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(error.response.data);
      }
    };

    // Fetch the data with optional the search value
    fetchData();
  }, [repositoryData.repositoryFolder, branchData, languageData.codename]);

  //
  // Fetch all the base schema data on the beginning
  //
  useEffect(() => {
    // Get all the base schema strings
    const fetchAllData = async () => {
      try {
        const baseSchemaToFetch = {
          repositoryName: repositoryData.repositoryFolder,
          branch: branchData['branch'],
        };

        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}language/fetch_schema`,
          baseSchemaToFetch,
          {
            headers: {
              'auth-token': localStorage.usertoken,
            },
          }
        );
        setBaseSchemaData(response.data.baseSchemaStrings);
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(error.response.data);
      }
    };

    // Fetch the data if the schema base data is empty
    if (baseSchemaData.length === 0) {
      fetchAllData();
    }
  }, [baseSchemaData, branchData, repositoryData.repositoryFolder]);

  //
  // Setup pagination everytime the data changes
  //
  useEffect(() => {
    setIsLoading(true);
    // Reset the nedded states
    let rows = [];
    setActualPage(0);
    setPrevDisable(true);
    setNextDisable(true);

    // Calculate how many pages we have correspond the data
    let pages = Math.round(data.length / itemsPerPage + 0.4 - 1);
    setPages(pages);

    // If we have more than one page, enable the next button
    if (pages > 1) {
      setNextDisable(false);
    }

    // Check if the data lenght is less than items per page value.
    // If yes, we set the data lenght to the for loop to avoid
    // adding undefined values on the array.
    let maxItems = itemsPerPage;
    if (data.length < itemsPerPage) {
      maxItems = data.length;
    }

    // Set the page data
    for (let i = 0; i < maxItems; i++) {
      rows.push(data[i]);
    }

    setPageData([...rows]);
    setIsLoading(false);
  }, [data, itemsPerPage]);

  //
  // Handle when previous button is clicked
  //
  const handlePrevClick = (e) => {
    // Get and update the new page state
    const prevPage = actualPage - 1;
    setActualPage(prevPage);

    // Define the new indexes to get the page data
    let startIndex = prevPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // Disable the prev button if we are
    // on the first page
    if (prevPage === 0) {
      setPrevDisable(true);
    }
    // Also enable the next button if not already
    if (nextDisable) {
      setNextDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  //
  // Handle when next button is clicked
  //
  const handleNextClick = (e) => {
    // Get and update the new page state
    const nextPage = actualPage + 1;
    setActualPage(nextPage);

    // Define the new indexes to get the page data
    let startIndex = nextPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // If we are on the last page, check if the
    // data are less than the end index.
    if (endIndex > data.length) {
      endIndex = data.length;
    }

    // Disable the next button if we are
    // on the last page
    if (nextPage === pages) {
      setNextDisable(true);
    }
    // Also enable the previous button if not already
    if (prevDisable) {
      setPrevDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  //
  // Save the changes in the database and the local/remote branch
  //
  const handleSubmitClick = (e) => {
    setIsSaving(true);

    // Prepare the data object
    const dataToUpdate = {
      repositoryName: repositoryData.repositoryFolder,
      branch: branchData['branch'],
      codename: languageData.codename,
      data: dataToSave,
    };

    // Save the changes and after the response, disable
    // the save button, empty the data to save array
    // and refresh the data array.
    languageUpdate(dataToUpdate).then((res) => {
      if (res) {
        setSaveDisable(true);
        setIsSaving(false);
        setDataToSave([]);
        refreshData();
      } else {
        // There is an error in the response (merge conflict).
        // Redirect to the branches page to update the branch.
        history.push('/branches');
      }
    });
  };

  //
  // Check if there is unsaved data
  //
  const handleBackClick = (e) => {
    // If save button is enabled, there
    // is unsaved data.
    if (!saveDisable) {
      alert('There are unsaved data');
    } else {
      history.push('/languages');
    }
  };

  //
  // Returns the correspond base schema value
  // using the item keyIndex.
  //
  const handleBaseSchemaValue = (keyIndex) => {
    const entry = baseSchemaData.filter((e) => e.keyIndex === keyIndex);
    if (entry.length !== 0) {
      return entry[0].value;
    } else {
      return '';
    }
  };

  //
  // Filter the all data array with the search value
  // using the starts with method and set the result
  // to the data array to trigger the page data array.
  //
  const handleSearchField = (e) => {
    setSearch(e.target.value);

    if (e.target.value) {
      const filteredEntries = allData.filter((entry) => {
        if (
          entry.value.toLowerCase().startsWith(e.target.value.toLowerCase()) &&
          (entry.status === dropdownStatus || dropdownStatus === '')
        ) {
          return true;
        } else {
          return false;
        }
      });
      setData(filteredEntries);
    } else {
      const filteredEntries = allData.filter((entry) => {
        if (entry.status === dropdownStatus || dropdownStatus === '') {
          return true;
        } else {
          return false;
        }
      });
      setData(filteredEntries);
    }
  };

  //
  // Filter the all data array with the dropdown status value
  // (and the search value if any) and set the result to the
  // data array to trigger the page data array.
  //
  const handleDropdownStatus = (e) => {
    setDropdownStatus(e.target.value);

    if (e.target.value) {
      const filteredEntries = allData.filter((entry) => {
        if (
          entry.status === e.target.value &&
          entry.value.toLowerCase().startsWith(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      setData(filteredEntries);
    } else {
      const filteredEntries = allData.filter((entry) => {
        if (entry.value.toLowerCase().startsWith(search.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      setData(filteredEntries);
    }
  };

  //
  // Refresh the all data array with the dropdown status value
  // and/or the search value if any, and set the result to the
  // data array to trigger the page data array.
  //
  const refreshData = (e) => {
    const searchAndDropdown = () => {
      const filteredEntries = allData.filter((entry) => {
        if (
          entry.status === dropdownStatus &&
          entry.value.toLowerCase().startsWith(search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });

      setData(filteredEntries);
    };

    const searchOnly = () => {
      const filteredEntries = allData.filter((entry) => {
        if (entry.value.toLowerCase().startsWith(search.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });

      setData(filteredEntries);
    };

    const dropdownOnly = () => {
      const filteredEntries = allData.filter((entry) => {
        if (entry.status === dropdownStatus || dropdownStatus === '') {
          return true;
        } else {
          return false;
        }
      });

      setData(filteredEntries);
    };

    // Fire the correspond method depending if there are values
    // in search and/or dropdown status.
    search && dropdownStatus
      ? searchAndDropdown()
      : search
      ? searchOnly()
      : dropdownOnly();
  };

  //
  // Change the correspond dropdown object field
  //
  const handleChangeValue = (e, item, source) => {
    // Set the new object
    let objectToApply = item;

    // Get the value from the correspond
    // source: field or dropdown.
    source === 'field'
      ? (objectToApply.value = e.target.value)
      : (objectToApply.status = e.target.value);

    // Filter out the correspond entry from the all data array
    let allDataFilteredArray = allData.filter(
      (e) => e.keyIndex !== item.keyIndex
    );

    // Filter out the correspond entry from the data to save array
    let dataToSaveFilteredArray = dataToSave.filter(
      (e) => e.keyIndex !== item.keyIndex
    );

    // Push the new object to all data array, sort the entries
    // based on key index variable and set.
    allDataFilteredArray.push(objectToApply);
    allDataFilteredArray.sort((a, b) => {
      return a.keyIndex - b.keyIndex;
    });
    setAllData(allDataFilteredArray);

    // Push the new object to save to data array and set it
    dataToSaveFilteredArray.push(objectToApply);
    setDataToSave(dataToSaveFilteredArray);
    setSaveDisable(false);
  };

  return (
    <div className="container fadeIn">
      <br />
      <h2 className="text-center">Translate Language</h2>
      <br />
      <div>
        <table className="table table-striped table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Repository</th>
              <th scope="col">Branch</th>
              <th scope="col">Language</th>
              <th scope="col">Codename</th>
              <th scope="col">Native Name</th>
            </tr>
          </thead>
          <tbody>
            <tr className="fadeIn">
              <td>{repositoryData.repositoryFolder}</td>
              <td>{branchData['branch']}</td>
              <td>{languageData.language}</td>
              <td>{languageData.codename}</td>
              <td>{languageData.nativeName}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row align-items-center justify-content-between">
        <div className="input-group col-md-4">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-default">
              Search
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Type search term"
            value={search}
            onChange={(e) => {
              handleSearchField(e);
            }}
            maxLength="34"
          />
        </div>
        <div className="input-group col-md-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-default">
              Status
            </span>
          </div>
          <select
            className="custom-select"
            onChange={(e) => {
              handleDropdownStatus(e);
            }}
          >
            <option value="">None</option>
            <option value={status[0]}>{status[0]}</option>
            <option value={status[1]}>{status[1]}</option>
            <option value={status[2]}>{status[2]}</option>
          </select>
        </div>
        <div className="input-group col-md-2">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-default">
              Items
            </span>
          </div>
          <select
            className="custom-select"
            onChange={(e) => {
              setItemsPerPage(parseInt(e.target.value));
            }}
          >
            <option defaultValue={staticItemsPerPage[0]}>
              {staticItemsPerPage[0]}
            </option>
            <option value={staticItemsPerPage[1]}>
              {staticItemsPerPage[1]}
            </option>
            <option value={staticItemsPerPage[2]}>
              {staticItemsPerPage[2]}
            </option>
            <option value={staticItemsPerPage[3]}>
              {staticItemsPerPage[3]}
            </option>
          </select>
        </div>
        <div className="input-group col-md-3 justify-content-end">
          <button
            className="btn btn-danger button-right"
            type="button"
            onClick={handleBackClick}
            disabled={isSaving}
          >
            <i className="bi bi-arrow-left"></i> Back
          </button>
          {isSaving ? (
            <>
              <button
                className="btn btn-success button-right"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{' '}
                Saving.
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-success button-right"
                type="button"
                onClick={handleSubmitClick}
                disabled={saveDisable}
              >
                <i className="bi bi-check-lg"></i> Submit
              </button>
            </>
          )}
        </div>
      </div>
      {isLoading ? (
        <>
          <h5 className="text-center fadeIn">Loading...</h5>
        </>
      ) : (
        <>
          <hr />
          <div className="form-row">
            <div className="form-group col-md-5">
              <label htmlFor="baseSchema">Base Schema</label>
            </div>
            <div className="form-group col-md-5">
              <label htmlFor="translations">
                {languageData.language} Translations
              </label>
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="translations">Status</label>
            </div>
          </div>
          {pageData.map((item, index) => (
            <div key={index} className="form-row md-1">
              <div className="form-group col-md-5">
                <textarea
                  key={index}
                  type="text"
                  rows="1"
                  className="form-control"
                  name="baseSchemaValue"
                  value={handleBaseSchemaValue(item.keyIndex)}
                  readOnly
                ></textarea>
              </div>
              <div className="form-group col-md-5">
                <textarea
                  key={index}
                  type="text"
                  rows="1"
                  className="form-control"
                  name="baseSchemaValue"
                  placeholder="Enter translation text"
                  value={item.value}
                  onChange={(e) => handleChangeValue(e, item, 'field')}
                ></textarea>
              </div>
              <div className="form-group col-md-2">
                <select
                  className="custom-select"
                  onChange={(e) => {
                    handleChangeValue(e, item, 'dropdown');
                  }}
                >
                  <option defaultValue={item.status}>{item.status}</option>
                  {item.status !== status[0] ? (
                    <option value={status[0]}>{status[0]}</option>
                  ) : (
                    <></>
                  )}
                  {item.status !== status[1] ? (
                    <option value={status[1]}>{status[1]}</option>
                  ) : (
                    <></>
                  )}
                  {item.status !== status[2] ? (
                    <option value={status[2]}>{status[2]}</option>
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            </div>
          ))}
          <br />
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handlePrevClick}
            disabled={prevDisable}
          >
            <i className="bi bi-arrow-left-square-fill"></i> Prev
          </button>
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handleNextClick}
            disabled={nextDisable}
          >
            Next <i className="bi bi-arrow-right-square-fill"></i>
          </button>
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default LanguageTranslate;

import { useState } from 'react';
import { repositoryCreate } from '../HelperFunctions';
import { useHistory } from 'react-router-dom';
import './index.css';

const RepositoryNew = () => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [data, setData] = useState({
    name: '',
    description: '',
    repositoryUrl: '',
    username: '',
    password: '',
  });

  // Submit the changes
  const onSubmit = (e) => {
    e.preventDefault();

    // Submit only if the repository url starts with https://
    if (data.repositoryUrl.startsWith('https://')) {
      // Set is loading to true to add
      // the loading indicator to submit button
      setIsLoading(true);

      repositoryCreate(data).then((res) => {
        if (res) {
          history.push('/translations');
        }
      });
    } else {
      // Else alert the user, set loading to false and empty the url field
      alert('The repository url must be an https:// entry');
      setIsLoading(false);
      setData((previous) => {
        return {
          ...previous,
          repositoryUrl: '',
        };
      });
    }
  };

  // Change the correspond object field
  const onChange = (e) => {
    let keyName = e.target.name;
    let value = e.target.value;
    setData((previous) => {
      return {
        ...previous,
        [keyName]: value,
      };
    });
    setSubmitDisable(false);
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <form onSubmit={onSubmit}>
            <h2 className="text-center">Create a new repository</h2>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Enter repository name"
                value={data.name}
                required
                onChange={onChange}
                maxLength="20"
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className="form-control"
                name="description"
                placeholder="Enter description"
                value={data.description}
                onChange={onChange}
                maxLength="50"
              />
            </div>
            <div className="form-group">
              <label htmlFor="repositoryUrl">Repository URL</label>
              <input
                type="text"
                className="form-control"
                name="repositoryUrl"
                placeholder="Copy / paste the repository url"
                value={data.repositoryUrl}
                required
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                className="form-control"
                name="username"
                placeholder="Please enter username"
                value={data.username}
                required
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Please enter password"
                value={data.password}
                required
                onChange={onChange}
              />
            </div>
            <div className="row justify-content-end">
              {isLoading ? (
                <>
                  <button
                    className="btn btn-success button-right"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    This may take a few minutes. Please wait...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-danger button-right"
                    type="button"
                    onClick={() => {
                      history.push('/translations');
                    }}
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success button-right"
                    disabled={submitDisable}
                  >
                    <i className="bi bi-check-lg"></i> Submit
                  </button>
                </>
              )}
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default RepositoryNew;

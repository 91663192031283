import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  userRoleUpdate,
  userDelete,
  userRoles,
} from '../../components/HelperFunctions';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './index.css';

const UserEdit = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const profileToEdit = location.state.params;
  const [submitDisable, setSubmitDisable] = useState(true);
  const [deleteProfile, setDeleteProfile] = useState('');
  const [profile, setProfile] = useState({
    username: profileToEdit.username,
    email: profileToEdit.email,
    firstname: profileToEdit.firstname,
    lastname: profileToEdit.lastname,
    role: profileToEdit.role,
    lastActive: profileToEdit.lastActive,
  });

  //
  // Get and return the date to locale string
  //
  const dateToLocalstring = (dateToChange) => {
    const getDate = new Date(dateToChange);
    const finalDate = getDate.toLocaleString();
    return finalDate;
  };

  //
  // Change the delete field
  //
  const onChangeDelete = (e) => {
    let value = e.target.value;
    setDeleteProfile(value);
    setSubmitDisable(false);
  };

  //
  // Change the user role from dropdown
  //
  const onDropdownChange = (e) => {
    let value = e.value;
    setProfile((previous) => {
      return {
        ...previous,
        role: value,
      };
    });
    setSubmitDisable(false);
  };

  //
  // Submit the changes
  //
  const onSubmit = (e) => {
    e.preventDefault();

    // Check if user choose to delete
    if (deleteProfile === 'DELETE') {
      userDelete(profileToEdit._id).then((res) => {
        if (res) {
          history.push('/users');
        }
      });
    } else {
      // Update the user role
      const newUserRole = { role: profile.role };
      userRoleUpdate(profileToEdit._id, newUserRole).then((res) => {
        if (res) {
          history.push('/users');
        }
      });
    }
  };

  return (
    <div className="container fadeIn">
      <div className="col-md-6 mt-5 mx-auto">
        <h1 className="text-center">User Profile</h1>
        <br />

        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              name="username"
              value={profile.username}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={profile.email}
              readOnly
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="firstname">Firstname</label>
              <input
                type="text"
                className="form-control"
                name="firstname"
                value={profile.firstname}
                readOnly
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastname">Lastname</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                value={profile.lastname}
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="role">User Role</label>
              <Dropdown
                options={userRoles}
                value={profile.role}
                onChange={onDropdownChange}
                placeholder="Select a user role..."
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lastActive">Last Active</label>
              <input
                type="text"
                className="form-control"
                name="lastActive"
                value={dateToLocalstring(profile.lastActive)}
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="delete" style={{ color: 'red' }}>
              Delete
            </label>
            <input
              type="text"
              className="form-control"
              name="delete"
              placeholder="Enter DELETE to delete the user profile"
              value={deleteProfile}
              onChange={onChangeDelete}
            />
          </div>
          <div className="row justify-content-end">
            <button
              className="btn btn-danger button-right"
              type="button"
              onClick={() => {
                history.push('/users');
              }}
            >
              <i className="bi bi-arrow-left"></i> Back
            </button>
            <button
              type="submit"
              className="btn btn-success button-right"
              disabled={submitDisable}
            >
              <i className="bi bi-check-lg"></i> Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserEdit;

import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl, showErrorMessage } from '../../components/HelperFunctions';
import './index.css';

export const ReportList = (props) => {
  const { repository, branch } = props;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //
  // Fetch the languages data from the branch
  //
  useEffect(() => {
    // Get all languages for the given branch
    const fetchAllData = async () => {
      try {
        const languagesToFetch = {
          repositoryName: repository,
          branchToFetch: branch,
        };

        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}language/get_languages`,
          languagesToFetch,
          {
            headers: {
              'auth-token': localStorage.usertoken,
            },
          }
        );
        setData(response.data.allLanguages);
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(error.response.data);
      }
    };

    // Fetch all data
    fetchAllData();
  }, [repository, branch]);

  return (
    <div className="container fadeIn">
      {isLoading ? (
        <>
          <h5 className="text-center">Loading...</h5>
        </>
      ) : (
        <>
          <h4>Branch: {branch}</h4>
          <h5>Strings: {data[0].strings}</h5>
          <table className="table table-striped table-sm table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Language</th>
                <th scope="col">Codename</th>
                <th scope="col">Native Name</th>
                <th scope="col" style={{ color: 'green' }}>
                  Translated
                </th>
                <th scope="col" style={{ color: 'orange' }}>
                  Fuzzy
                </th>
                <th scope="col" style={{ color: 'red' }}>
                  Untranslated
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="fadeIn">
                  <th scope="col">{index + 1}</th>
                  <td>{item.language}</td>
                  <td>{item.codename}</td>
                  <td>{item.nativeName}</td>
                  <td style={{ color: 'green' }}>{item.translated}%</td>
                  <td style={{ color: 'orange' }}>{item.fuzzy}%</td>
                  <td style={{ color: 'red' }}>{item.untranslated}%</td>
                </tr>
              ))}
            </tbody>
          </table>

          <br />
        </>
      )}
    </div>
  );
};

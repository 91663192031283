import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getToken } from '../../components/HelperFunctions';

const Profile = () => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    role: '',
    lastActive: '',
  });

  //
  // Decode the token and set the info
  // for the logged in user
  //
  useEffect(() => {
    setIsLoading(true);
    const decoded = getToken();

    // Set the last active date from the token
    const lastActiveString = new Date(decoded.user.lastActive).toLocaleString();

    setProfile({
      username: decoded.user.username,
      email: decoded.user.email,
      firstname: decoded.user.firstname,
      lastname: decoded.user.lastname,
      role: decoded.user.role,
      lastActive: lastActiveString,
    });

    setIsLoading(false);
  }, []);

  //
  // Handle click for the edit profile button
  //
  const handleClick = (e) => {
    history.push('/profile_edit');
  };

  return (
    <div className="container fadeIn">
      <div className="col-md-6 mt-5 mx-auto">
        <h2 className="text-center">User Profile</h2>
        <br />
        {isLoading ? (
          <>Loading...</>
        ) : (
          <>
            <form>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={profile.username}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={profile.email}
                  readOnly
                />
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="firstname">Firstname</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstname"
                    value={profile.firstname}
                    readOnly
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="lastname">Lastname</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastname"
                    value={profile.lastname}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="role">User Role</label>
                  <input
                    type="text"
                    className="form-control"
                    name="role"
                    value={profile.role}
                    readOnly
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="lastActive">Last Active</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastActive"
                    value={profile.lastActive}
                    readOnly
                  />
                </div>
              </div>
            </form>
            <div className="row justify-content-end">
              <button
                className="btn btn-danger button-right"
                type="button"
                onClick={() => {
                  history.push('/');
                }}
              >
                <i className="bi bi-arrow-left"></i> Back
              </button>
              <button
                className="btn btn-success button-right"
                type="button"
                onClick={handleClick}
              >
                <i className="bi bi-pencil"></i> Edit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;

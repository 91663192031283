import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { JsonEditor } from 'react-jsondata-editor';
import { RepositoryContext } from '../../context/RepositoryContext';
import { BranchContext } from '../../context/BranchContext';
import {
  apiUrl,
  updateSchema,
  showErrorMessage,
} from '../../components/HelperFunctions';
import axios from 'axios';
import './index.css';

const Schema = () => {
  let history = useHistory();
  const [repositoryData] = useContext(RepositoryContext);
  const [branchData] = useContext(BranchContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  // Json data from server
  const [data, setData] = useState('');
  // Json data from local file
  const [newSchema, setNewSchema] = useState('');
  // Temporary file data. Used in apply button.
  const [fileData, setFileData] = useState();
  // Enable / disable save / apply buttons
  const [saveDisable, setSaveDisable] = useState(true);
  const [applyDisable, setApplyDisable] = useState(true);
  // Used in file picker text label
  const [fileChoosen, setFileChoosen] = useState('Choose .json file');

  //
  // Fetch the data on the beginning
  //
  useEffect(() => {
    const fetchBasicLanguage = async () => {
      try {
        const baseSchemaToFetch = {
          repositoryName: repositoryData.repositoryFolder,
          branch: branchData['branch'],
        };

        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}language/get_schema`,
          baseSchemaToFetch,
          {
            headers: {
              'auth-token': localStorage.usertoken,
            },
          }
        );
        setData(JSON.stringify(response.data));
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(error.response.data);
      }
    };

    fetchBasicLanguage();
  }, [branchData, repositoryData.repositoryFolder]);

  //
  // Read and insert in json editor the json file data
  // after the apply button is clicked.
  //
  const handleApplyFileClick = () => {
    // Check if there is a selected file
    if (fileData) {
      // Callback function when fileReader ends
      const handleFileRead = (e) => {
        const fileContent = fileReader.result;

        // Set the new data to json editor,
        // empty the newSchema, fileChoosen, fileData variables,
        // disable the apply button and active the save button.
        setData(fileContent);
        setNewSchema('');
        setFileChoosen('Choose .json file');
        setFileData('');
        setSaveDisable(false);
        setApplyDisable(true);
      };

      let fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(fileData);
    }
  };

  //
  // Update the label in file picker after a file is choosen
  //
  const handleChangeFileClick = (e) => {
    // Check if the user choose a file or canceled
    if (e.target.files.length !== 0) {
      const newFileData = e.target.files[0];
      const choosenFile = e.target.files[0].name;
      setFileData(newFileData);
      setFileChoosen(choosenFile);
      setApplyDisable(false);
    }
  };

  //
  // Update the changes from json editor
  //
  const handleJsonEditorChanges = (output) => {
    setNewSchema(output);
    setSaveDisable(false);
  };

  //
  // Save the changes in json schema
  //
  const handleSaveClick = (e) => {
    let jsonData = '';

    // Set is updating to true to add
    // the loading indicator to submit button
    setIsUpdating(true);

    // Check wich json data will be updated.
    // If newSchema have data, the changes are from json editor.
    if (newSchema) {
      jsonData = newSchema;
    } else {
      // Else the changes are from file upload
      jsonData = data;
    }

    // Prepare the data and send to update the json files
    const newData = {
      repositoryName: repositoryData.repositoryFolder,
      branch: branchData['branch'],
      newBaseSchema: jsonData,
    };
    updateSchema(newData).then((res) => {
      if (res) {
        history.push('/languages');
      } else {
        // There is an error in the response (merge conflict).
        // Redirect to the branches page to update the branch.
        history.push('/branches');
      }
    });
  };

  //
  // Check if there is unsaved data
  //
  const handleBackClick = (e) => {
    // If save button is enabled, there
    // is unsaved data.
    if (!saveDisable) {
      alert('There are unsaved data');
    } else {
      history.push('/languages');
    }
  };

  return (
    <div className="container fadeIn">
      <div className="col-md-6 mt-2 mx-auto">
        <br />
        <h2 className="text-center">Edit Base Schema</h2>
        <div className="row justify-content-end">
          {isUpdating ? (
            <>
              <button
                className="btn btn-success button-right"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                This may take a few minutes. Please wait...
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-danger button-right"
                type="button"
                onClick={handleBackClick}
              >
                <i className="bi bi-arrow-left"></i> Back
              </button>
              <button
                className="btn btn-success button-right"
                type="button"
                onClick={handleSaveClick}
                disabled={saveDisable}
              >
                <i className="bi bi-check-lg"></i> Submit
              </button>
            </>
          )}
          <div className="input-group button-file">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="uploadJsonFile"
                accept=".json"
                onChange={handleChangeFileClick}
              />
              <label className="custom-file-label" htmlFor="uploadJsonFile">
                {fileChoosen}
              </label>
            </div>
            <div className="input-group-append">
              <button
                className="btn btn-info"
                type="button"
                onClick={handleApplyFileClick}
                disabled={applyDisable}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <></>
        ) : (
          <>
            <div
              className="row justify-content-center"
              style={{
                height: '600px',
                width: '530px',
                border: 'solid 1px #dddddd',
              }}
            >
              <JsonEditor
                jsonObject={data}
                onChange={(output) => {
                  handleJsonEditorChanges(output);
                }}
              />
            </div>
          </>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default Schema;

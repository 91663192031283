import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  apiUrl,
  userRoles,
  showErrorMessage,
  languageDownload,
  languageDownloadAll,
} from '../../components/HelperFunctions';
import { RepositoryContext } from '../../context/RepositoryContext';
import { BranchContext } from '../../context/BranchContext';
import axios from 'axios';
import './index.css';

const Languages = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [repositoryData] = useContext(RepositoryContext);
  const [branchData] = useContext(BranchContext);
  const [isLoading, setIsLoading] = useState(true);

  // States for pagination
  const [pages, setPages] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(true);
  const [pageData, setPageData] = useState([]);
  const itemsPerPage = 10;

  //
  // Fetch the languages data from the branch
  //
  useEffect(() => {
    // Get all languages for the given branch
    const fetchAllData = async () => {
      try {
        const languagesToFetch = {
          repositoryName: repositoryData.repositoryFolder,
          branchToFetch: branchData['branch'],
        };

        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}language/get_languages`,
          languagesToFetch,
          {
            headers: {
              'auth-token': localStorage.usertoken,
            },
          }
        );
        setData(response.data.allLanguages);
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(error.response.data);
      }
    };

    // Fetch all data
    fetchAllData();
  }, [branchData, repositoryData.repositoryFolder]);

  //
  // Setup pagination everytime the data changes
  //
  useEffect(() => {
    setIsLoading(true);
    // Reset the nedded states
    let rows = [];
    setActualPage(0);
    setPrevDisable(true);
    setNextDisable(true);

    // Calculate how many pages we have correspond the data
    let pages = Math.round(data.length / itemsPerPage + 0.4 - 1);
    setPages(pages);

    // If we have more than one page, enable the next button
    if (pages > 0) {
      setNextDisable(false);
    }

    // Check if the data lenght is less than items per page value.
    // If yes, we set the data lenght to the for loop to avoid
    // adding undefined values on the array.
    let maxItems = itemsPerPage;
    if (data.length < itemsPerPage) {
      maxItems = data.length;
    }

    // Set the page data
    for (let i = 0; i < maxItems; i++) {
      rows.push(data[i]);
    }

    setPageData([...rows]);
    setIsLoading(false);
  }, [data]);

  //
  // Route to language stats with the correspond id
  //
  const handleReportClick = (e) => {
    const LanguageReport = pageData[e.target.id];
    history.push('/language_report', { params: LanguageReport });
  };

  //
  // Route to language edit with the correspond id
  //
  const handleEditClick = (e) => {
    const languageToEdit = pageData[e.target.id];
    history.push('/language_edit', { params: languageToEdit });
  };

  //
  // Download all the language json files in zip
  //
  const handleDownloadAllClick = () => {
    // Prepare the data object
    const data = {
      repositoryName: repositoryData.repositoryFolder,
      branch: branchData['branch'],
    };
    languageDownloadAll(data).then((res) => {
      if (res) {
        history.push('/languages');
      }
    });
  };

  //
  // Download the correspond language json file in zip
  //
  const handleDownloadClick = (e) => {
    const languageToDownload = pageData[e.target.id];

    // Prepare the data object
    const data = {
      repositoryName: repositoryData.repositoryFolder,
      branch: branchData['branch'],
      codename: languageToDownload.codename,
    };
    languageDownload(data).then((res) => {
      if (res) {
        history.push('/languages');
      }
    });
  };

  //
  // Route to laguage with the correspond branch id
  //
  const handleSelectClick = (e) => {
    const languageSelected = pageData[e.target.id];
    history.push('/language_translate', { params: languageSelected });
  };

  //
  // Handle when previous button is clicked
  //
  const handlePrevClick = (e) => {
    // Get and update the new page state
    const prevPage = actualPage - 1;
    setActualPage(prevPage);

    // Define the new indexes to get the page data
    let startIndex = prevPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // Disable the prev button if we are
    // on the first page
    if (prevPage === 0) {
      setPrevDisable(true);
    }
    // Also enable the next button if not already
    if (nextDisable) {
      setNextDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  //
  // Handle when next button is clicked
  //
  const handleNextClick = (e) => {
    // Get and update the new page state
    const nextPage = actualPage + 1;
    setActualPage(nextPage);

    // Define the new indexes to get the page data
    let startIndex = nextPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // If we are on the last page, check if the
    // data are less than the end index.
    if (endIndex > data.length) {
      endIndex = data.length;
    }

    // Disable the next button if we are
    // on the last page
    if (nextPage === pages) {
      setNextDisable(true);
    }
    // Also enable the previous button if not already
    if (prevDisable) {
      setPrevDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  return (
    <div className="container fadeIn">
      <br />
      <h2 className="text-center">Languages</h2>
      <br />
      <div className="row justify-content-end">
        <button
          className="btn btn-danger button-right"
          type="button"
          onClick={() => {
            history.push('/branches');
          }}
        >
          <i className="bi bi-arrow-left"></i> Back
        </button>
        <button
          className="btn btn-primary button-right"
          type="button"
          onClick={handleDownloadAllClick}
        >
          <i className="bi bi-download"></i> Download
        </button>
        {localStorage.userrole === userRoles[0] && (
          <>
            <button
              className="btn btn-info button-right"
              type="button"
              onClick={() => {
                history.push('/schema_edit');
              }}
            >
              <i className="bi bi-gear"></i> Schema
            </button>
            <button
              className="btn btn-success button-right"
              type="button"
              onClick={() => {
                history.push('/language_new', { params: data });
              }}
            >
              <i className="bi bi-plus-lg"></i> New
            </button>
          </>
        )}
      </div>
      <br />
      {isLoading ? (
        <>
          <h5 className="text-center">Loading...</h5>
        </>
      ) : (
        <>
          <h6>Branch: {branchData['branch']}</h6>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Language</th>
                <th scope="col">Codename</th>
                <th scope="col">Native Name</th>
                <th scope="col">Report</th>
                <th scope="col">Edit</th>
                <th scope="col">Download</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody>
              {pageData.map((item, index) => (
                <tr key={index} className="fadeIn">
                  <th scope="col">{actualPage * itemsPerPage + (index + 1)}</th>
                  <td>{item['language']}</td>
                  <td>{item['codename']}</td>
                  <td>{item['nativeName']}</td>
                  <td>
                    <button className="btn" type="button">
                      <i
                        className="bi bi-bar-chart-line"
                        onClick={handleReportClick}
                        id={index}
                      ></i>
                    </button>
                  </td>
                  <td>
                    {localStorage.userrole === userRoles[0] && (
                      <button className="btn" type="button">
                        <i
                          className="bi bi-pencil"
                          onClick={handleEditClick}
                          id={index}
                        ></i>
                      </button>
                    )}
                  </td>
                  <td>
                    <button className="btn" type="button">
                      <i
                        className="bi bi-download"
                        onClick={handleDownloadClick}
                        id={index}
                      ></i>
                    </button>
                  </td>
                  <td>
                    <button className="btn" type="button">
                      <i
                        className="bi bi-arrow-right-circle"
                        onClick={handleSelectClick}
                        id={index}
                      ></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handlePrevClick}
            disabled={prevDisable}
          >
            <i className="bi bi-arrow-left-square-fill"></i> Prev
          </button>
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handleNextClick}
            disabled={nextDisable}
          >
            Next <i className="bi bi-arrow-right-square-fill"></i>
          </button>
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default Languages;

import { useState, useContext } from 'react';
import {
  translationStatusUpdate,
  languageDelete,
  status,
  baseLanguage,
} from '../HelperFunctions';
import { useHistory, useLocation } from 'react-router-dom';
import { RepositoryContext } from '../../context/RepositoryContext';
import { BranchContext } from '../../context/BranchContext';
import './index.css';

const LanguageEdit = () => {
  let history = useHistory();

  // Get the params and set them to data object
  const location = useLocation();
  const languageData = location.state.params;
  const [repositoryData] = useContext(RepositoryContext);
  const [branchData] = useContext(BranchContext);
  const [dropdownStatus, setDropdownStatus] = useState('');
  const [deleteLanguage, setDeleteLanguage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);

  //
  // Submit the changes
  //
  const onSubmit = (e) => {
    e.preventDefault();

    // Set is loading to true to add
    // the loading indicator to submit button
    setIsLoading(true);

    // Check if user choose to delete
    if (deleteLanguage === 'DELETE') {
      const data = {
        repositoryName: repositoryData.repositoryFolder,
        branch: branchData['branch'],
        languageToDelete: languageData['codename'],
      };
      languageDelete(data).then((res) => {
        if (res) {
          history.push('/languages');
        } else {
          // There is an error in the response (merge conflict).
          // Redirect to the branches page to update the branch.
          history.push('/branches');
        }
      });
    } else {
      // Else, check if the user choose a translation status
      if (status.includes(dropdownStatus)) {
        const data = {
          repositoryName: repositoryData.repositoryFolder,
          branch: branchData['branch'],
          codename: languageData.codename,
          status: dropdownStatus,
        };
        // Update the data in branches and database
        translationStatusUpdate(data).then((res) => {
          if (res) {
            history.push('/languages');
          } else {
            // There is an error in the response (merge conflict).
            // Redirect to the branches page to update the branch.
            history.push('/branches');
          }
        });
      }
    }
  };

  //
  // Update the dropdown status value
  //
  const handleDropdownStatus = (e) => {
    let value = e.target.value;
    setDropdownStatus(value);

    // Reset the delete language value
    // if dropdown status has a value.
    if (value) {
      setDeleteLanguage('');
    }

    // Enable / disable the submit button
    // using the delete language and dropdown values.
    if (deleteLanguage || value) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  };

  //
  // Change the delete field
  //
  const handleOnChangeDelete = (e) => {
    let value = e.target.value;
    setDeleteLanguage(value);

    // Reset the dropdown status value
    // if delete language has a value.
    if (value) {
      setDropdownStatus('');
    }

    // Enable / disable the submit button
    // using the delete language and dropdown values.
    if (dropdownStatus || value) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <form onSubmit={onSubmit}>
            <h2 className="text-center">Edit the Language</h2>
            <br />
            <div className="form-group">
              <label htmlFor="branch">Branch</label>
              <input
                type="text"
                className="form-control"
                name="branch"
                value={branchData['branch']}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="branch">Language</label>
              <input
                type="text"
                className="form-control"
                name="language"
                value={languageData['language']}
                readOnly
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="firstname">Codename</label>
                <input
                  type="text"
                  className="form-control"
                  name="codename"
                  value={languageData['codename']}
                  readOnly
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lastname">Native Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="nativeName"
                  value={languageData['nativeName']}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="translation_status">
                Set all language entries to Translation Status:
              </label>
              <select
                className="custom-select"
                onChange={(e) => {
                  handleDropdownStatus(e);
                }}
              >
                <option value="">None</option>
                <option value={status[0]}>{status[0]}</option>
                <option value={status[1]}>{status[1]}</option>
                <option value={status[2]}>{status[2]}</option>
              </select>
              <small
                id="translationStatusHelpBlock"
                className="form-text text-muted"
              >
                Please note that these changes are permanent and cannot be
                undone
              </small>
            </div>
            {languageData['codename'] !== baseLanguage && (
              <div className="form-group">
                <label htmlFor="delete" style={{ color: 'red' }}>
                  Delete
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="delete"
                  placeholder="Enter DELETE to delete the language"
                  value={deleteLanguage}
                  onChange={(e) => {
                    handleOnChangeDelete(e);
                  }}
                />
              </div>
            )}
            <div className="row justify-content-end">
              {isLoading ? (
                <>
                  <button
                    className="btn btn-success button-right"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    This may take a few minutes. Please wait...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-danger button-right"
                    type="button"
                    onClick={() => {
                      history.push('/languages');
                    }}
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success button-right"
                    disabled={submitDisable}
                  >
                    <i className="bi bi-check-lg"></i> Submit
                  </button>
                </>
              )}
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default LanguageEdit;

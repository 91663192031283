import { useState, useEffect, useContext } from 'react';
import {
  branchCreate,
  standartBranches,
  translateBranchPrefix,
} from '../HelperFunctions';
import { useHistory } from 'react-router-dom';
import { RepositoryContext } from '../../context/RepositoryContext';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './index.css';

const BranchNew = () => {
  let history = useHistory();
  const [branches, setBranches] = useState([]);
  const [newBranch, setNewBranch] = useState('');
  const [repositoryData] = useContext(RepositoryContext);
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [data, setData] = useState({
    repositoryName: repositoryData.repositoryFolder,
    newBranch: `${translateBranchPrefix}${
      Object.keys(repositoryData.branches).includes('main') ? 'main' : 'master'
    }-${newBranch}`,
    cloneFromBranch: Object.keys(repositoryData.branches).includes('main')
      ? 'main'
      : 'master',
  });

  //
  // Set the standart branches
  //
  useEffect(() => {
    // Set the standard branches from selected repository data
    const getBranches = async () => {
      try {
        Object.keys(repositoryData.branches).forEach((e) => {
          if (standartBranches.includes(e)) {
            setBranches((previous) => {
              return [...previous, e];
            });
          }
        });
      } catch (error) {
        alert(error);
      }
    };

    // Set the branches if not already
    if (branches.length === 0) {
      getBranches();
    }
  }, [branches, repositoryData]);

  //
  // Submit the changes
  //
  const onSubmit = (e) => {
    e.preventDefault();

    // Set is loading to true to add
    // the loading indicator to submit button
    setIsLoading(true);

    // Create the new branch only if not already exist
    if (branches.includes(data.newBranch)) {
      alert('Error. The given branch already exist');
      setIsLoading(false);
      setData((previous) => {
        return {
          ...previous,
          newBranch: '',
        };
      });
    } else {
      branchCreate(data).then((res) => {
        if (res) {
          setIsLoading(false);
          history.push('/branches');
        }
      });
    }
  };

  //
  // Change the branch distinct name field
  //
  const onChange = (e) => {
    let value = e.target.value;
    setNewBranch(value);
    setData((previous) => {
      return {
        ...previous,
        newBranch: `${translateBranchPrefix}${data.cloneFromBranch}-${value}`,
      };
    });

    // Enable / disable the submit button
    // using the delete language and dropdown values.
    if (value) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  };

  //
  // Change the branch to clone from dropdown
  //
  const onDropdownChange = (e) => {
    let value = e.value;
    setData((previous) => {
      return {
        ...previous,
        newBranch: `${translateBranchPrefix}${value}-${newBranch}`,
        cloneFromBranch: value,
      };
    });
  };

  return (
    <div className="container fadeIn">
      <div className="row">
        <div className="col-md-6 mt-5 mx-auto">
          <form onSubmit={onSubmit}>
            <h2 className="text-center">Create a new branch</h2>
            <br />
            <div className="form-group">
              <label htmlFor="branchDistinctName">Branch distinct name</label>
              <input
                type="text"
                className="form-control"
                name="branchDistinctName"
                placeholder="Enter branch distinct name"
                value={newBranch}
                required
                onChange={onChange}
                pattern="^[a-zA-Z0-9-_]{3,20}$"
              />
              <small id="brachHelpBlock" className="form-text text-muted">
                The brach distinct name must be 3-20 characters long and can
                contain:
                <br />
                alphanumeric characters [a-z] [A-Z] [0-9]
                <br />
                underscore "_" and (or) dash "-"
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="branchToCloneFrom">Branch to create</label>{' '}
              <input
                type="text"
                className="form-control"
                name="firstname"
                value={data.newBranch}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="branchToCloneFrom">Branch to clone from</label>{' '}
              <Dropdown
                options={branches}
                onChange={onDropdownChange}
                value={data.cloneFromBranch}
                placeholder="Select a branch to clone from"
              />
            </div>
            <div className="row justify-content-end">
              {isLoading ? (
                <>
                  <button
                    className="btn btn-success button-right"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    This may take a few minutes. Please wait...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-danger button-right"
                    type="button"
                    onClick={() => {
                      history.push('/branches');
                    }}
                  >
                    <i className="bi bi-arrow-left"></i> Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success button-right"
                    disabled={submitDisable}
                  >
                    <i className="bi bi-check-lg"></i> Submit
                  </button>
                </>
              )}
            </div>
          </form>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default BranchNew;

import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { RepositoryContext } from '../../context/RepositoryContext';
import {
  apiUrl,
  userRoles,
  showErrorMessage,
} from '../../components/HelperFunctions';
import axios from 'axios';
import './index.css';

const Repositories = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [, setRepository] = useContext(RepositoryContext);
  const [isLoading, setIsLoading] = useState(true);
  // States for pagination
  const [pages, setPages] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [prevDisable, setPrevDisable] = useState(true);
  const [nextDisable, setNextDisable] = useState(true);
  const [pageData, setPageData] = useState([]);
  const itemsPerPage = 10;

  //
  // Fetch the data on the beginning
  //
  useEffect(() => {
    // Get all repositories
    const fetchAllData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiUrl}repository/`, {
          headers: {
            'auth-token': localStorage.usertoken,
          },
        });
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        showErrorMessage(error.response.data);
      }
    };

    // Fetch all data
    fetchAllData();
  }, []);

  //
  // Setup pagination everytime the data changes
  //
  useEffect(() => {
    setIsLoading(true);
    // Reset the nedded states
    let rows = [];
    setActualPage(0);
    setPrevDisable(true);
    setNextDisable(true);

    // Calculate how many pages we have correspond the data
    let pages = Math.round(data.length / itemsPerPage + 0.4 - 1);
    setPages(pages);

    // If we have more than one page, enable the next button
    if (pages > 0) {
      setNextDisable(false);
    }

    // Check if the data lenght is less than items per page value.
    // If yes, we set the data lenght to the for loop to avoid
    // adding undefined values on the array.
    let maxItems = itemsPerPage;
    if (data.length < itemsPerPage) {
      maxItems = data.length;
    }

    // Set the page data
    for (let i = 0; i < maxItems; i++) {
      rows.push(data[i]);
    }

    setPageData([...rows]);
    setIsLoading(false);
  }, [data]);

  //
  // Route to repository stats with the correspond id
  //
  const handleReportClick = (e) => {
    const repositoryReport = pageData[e.target.id];
    history.push('/repository_report', { params: repositoryReport.data });
  };

  //
  // Route to repositoryEdit with the correspond id
  //
  const handleEditClick = (e) => {
    const repositoryToEdit = pageData[e.target.id];
    history.push('/repository_edit', { params: repositoryToEdit.data });
  };

  //
  // Route to repositoryBranches with the correspond id
  //
  const handleSelectClick = (e) => {
    const repositorySelected = pageData[e.target.id];

    // Set the selected repository data to repository context
    setRepository(repositorySelected.data);

    history.push('/branches', { params: repositorySelected.data });
  };

  //
  // Handle when previous button is clicked
  //
  const handlePrevClick = (e) => {
    // Get and update the new page state
    const prevPage = actualPage - 1;
    setActualPage(prevPage);

    // Define the new indexes to get the page data
    let startIndex = prevPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // Disable the prev button if we are
    // on the first page
    if (prevPage === 0) {
      setPrevDisable(true);
    }
    // Also enable the next button if not already
    if (nextDisable) {
      setNextDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  //
  // Handle when next button is clicked
  //
  const handleNextClick = (e) => {
    // Get and update the new page state
    const nextPage = actualPage + 1;
    setActualPage(nextPage);

    // Define the new indexes to get the page data
    let startIndex = nextPage * itemsPerPage;
    let endIndex = startIndex + itemsPerPage;

    // If we are on the last page, check if the
    // data are less than the end index.
    if (endIndex > data.length) {
      endIndex = data.length;
    }

    // Disable the next button if we are
    // on the last page
    if (nextPage === pages) {
      setNextDisable(true);
    }
    // Also enable the previous button if not already
    if (prevDisable) {
      setPrevDisable(false);
    }

    // Setup the new page data
    let rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      rows.push(data[i]);
    }
    setPageData([...rows]);
  };

  //
  // Get and return the date to locale string
  //
  const dateToLocalstring = (dateToChange) => {
    const getDate = new Date(dateToChange);
    const finalDate = getDate.toLocaleDateString();
    return finalDate;
  };

  return (
    <div className="container fadeIn">
      <br />
      <h2 className="text-center">Repositories</h2>
      <br />
      <div className="row justify-content-end">
        <button
          className="btn btn-danger button-right"
          type="button"
          onClick={() => {
            history.push('/');
          }}
        >
          <i className="bi bi-arrow-left"></i> Back
        </button>
        {localStorage.userrole === userRoles[0] && (
          <button
            className="btn btn-success button-right"
            type="button"
            onClick={() => {
              history.push('/repository_new');
            }}
          >
            <i className="bi bi-plus-lg"></i> New
          </button>
        )}
      </div>
      <br />
      {isLoading ? (
        <>
          <h5 className="text-center">Loading...</h5>
        </>
      ) : (
        <>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Last updated</th>
                <th scope="col">Report</th>
                <th scope="col">Edit</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody>
              {pageData.map((item, index) => (
                <tr key={index} className="fadeIn">
                  <th scope="col">{actualPage * itemsPerPage + (index + 1)}</th>
                  <td>{item.data.name}</td>
                  <td>{item.data.description}</td>
                  <td>{dateToLocalstring(item.data.lastUpdated)}</td>
                  <td>
                    <button className="btn" type="button">
                      <i
                        className="bi bi-bar-chart-line"
                        onClick={handleReportClick}
                        id={index}
                      ></i>
                    </button>
                  </td>
                  <td>
                    {localStorage.userrole === userRoles[0] && (
                      <button className="btn" type="button">
                        <i
                          className="bi bi-pencil"
                          onClick={handleEditClick}
                          id={index}
                        ></i>
                      </button>
                    )}
                  </td>
                  <td>
                    {item['active'] ? (
                      <button className="btn" type="button">
                        <i
                          className="bi bi-arrow-right-circle"
                          onClick={handleSelectClick}
                          id={index}
                        ></i>
                      </button>
                    ) : (
                      <i className="bi bi-x-lg"></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handlePrevClick}
            disabled={prevDisable}
          >
            <i className="bi bi-arrow-left-square-fill"></i> Prev
          </button>
          <button
            className="btn btn-dark pagination-button"
            type="button"
            onClick={handleNextClick}
            disabled={nextDisable}
          >
            Next <i className="bi bi-arrow-right-square-fill"></i>
          </button>
          <br />
          <br />
        </>
      )}
    </div>
  );
};

export default Repositories;
